.date-picker {
    border: 0;
    color: #69707a;
    font-size: 1rem;
    border: 1px solid #d4d6d9;
    padding: 0.5rem 0.5rem;
    appearance: none;
    border-radius: 6px;
    background-color: #fff;
  }
  .date-picker:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #F0824F;
    border-color: #f44336;
    border-width: 0;
  }
  .date-picker:hover {
    border-color: #f44336;
  }